/** @module featuredCaseStudiesCarousel */

const getFlickity = () => import('flickity');
const getFlickityAsNavFor = () => import('flickity-as-nav-for');
const getFlickityImagesLoaded = () => import('flickity-imagesloaded');
const getFlickityStyles = () => import('flickity/dist/flickity.css');

/*
 * Create carousels from all elements with '.js-carousel'
 * Uses 'data-navfor' to connect carousels together (e.g. thumbnails)
 * Carousel controls are added via a template and not Flickity itself
 */
export default function init() {
  const slider = document.querySelector('.js-featured-case-studies-carousel');
  if (slider) {
    Promise.all([
      getFlickity(),
      getFlickityStyles(),
      getFlickityAsNavFor(),
      getFlickityImagesLoaded(),
    ]).then(([module]) => {
      const Flickity = module.default;
      const carousel = new Flickity(slider, {
        pageDots: slider.hasAttribute('data-dots'),
        imagesLoaded: true,
        lazyLoad: true,
        draggable: true,
        groupCells: true,
      });
      carousel.on('lazyLoad', (event, cellElement) => {
        let img;
        if (cellElement.tagName.toLowerCase() === 'button') {
          img = cellElement.querySelector('.flickity-lazyloaded');
        } else {
          img = cellElement;
        }
        if (img.hasAttribute('data-sizes')) {
          img.setAttribute('sizes', img.dataset.sizes);
          img.removeAttribute('data-sizes');
        }
      });
    });
  }
}
